// Types
import { Action } from "./types";

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchEmployees = (data: any): Action => ({
  type: "FETCH_EMPLOYEES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateEmployees = (data: any): Action => ({
  type: "UPDATE_EMPLOYEES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const resendEmployeeInvite = (data: any): Action => ({
  type: "RESEND_EMPLOYEE_EMAIL",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const patchEmployee = (data: any): Action => ({
  type: "PATCH_EMPLOYEE",
  data,
});

export const terminateEmployee = (data: any): Action => ({
  type: "TERMINATE_EMPLOYEE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const fetchFullEmployees = (data?: any): Action => ({
  type: "FETCH_FULL_EMPLOYEES",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateFullEmployees = (data: any): Action => ({
  type: "UPDATE_FULL_EMPLOYEES",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearFullEmployees = (): Action => ({
  type: "CLEAR_FULL_EMPLOYEES",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const createEmployee = (data: any): Action => ({
  type: "CREATE_EMPLOYEE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const editEmployee = (data: any): Action => ({
  type: "EDIT_EMPLOYEE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateEmployee = (data: any): Action => ({
  type: "UPDATE_EMPLOYEE",
  data,
});

/**
 * @return {*}  {Action}
 */
export const getEmployee = (): Action => ({
  type: "GET_EMPLOYEE",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const getPlanDetails = (data?: any): Action => ({
  type: "GET_PLAN_DETAILS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updatePlanDetails = (data: any): Action => ({
  type: "UPDATE_PLAN_DETAILS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const getPlanHSAWSA = (data?: any): Action => ({
  type: "GET_PLAN_HSA_WSA",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updatePlanHSAWSA = (data: any): Action => ({
  type: "UPDATE_PLAN_HSA_WSA",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearEmployee = (): Action => ({
  type: "CLEAR_EMPLOYEE",
});

export const fetchEmployeeDetails = (data: any): Action => ({
  type: "FETCH_EMPLOYEE_DETAILS",
  data,
});

export const updateEmployeeDetails = (data: any): Action => ({
  type: "UPDATE_EMPLOYEE_DETAILS",
  data,
});

export const storeEmployeeDetails = (data: any): Action => ({
  type: "STORE_EMPLOYEE_DETAILS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateEmployeeCardDetails = (data: any): Action => {
  return {
    type: "UPDATE_CARD_DETAILS",
    data,
  };
};

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const activateEmployeeCard = (data: any): Action => {
  return {
    type: "ACTIVATE_EMPLOYEE_CARD",
    data,
  };
};

export const fetchEmployeeCardDetails = (data: any): Action => {
  return {
    type: "FETCH_EMPLOYEE_INVITE",
    data,
  };
};

export const fetchEmployeeCraHsaInfo = (): Action => {
  return {
    type: "FETCH_EMPLOYEE_CRA_HSA_INFO",
  };
};

export const updateEmployeeCraHsaInfo = (data: any): Action => {
  return {
    type: "UPDATE_EMPLOYEE_CRA_HSA_INFO",
    data,
  };
};

/**
 * @return {*}  {Action}
 */
export const fetchEmployeeCards = (data: any): Action => {
  return {
    type: "FETCH_EMPLOYEE_CARDS",
    data,
  };
};

/**
 * @return {*}  {Action}
 */
export const clearEmployeeCards = (): Action => {
  return {
    type: "CLEAR_EMPLOYEE_CARDS",
  };
};

export const clearEmployeeCraHsaInfo = (): Action => {
  return {
    type: "CLEAR_EMPLOYEE_CRA_HSA_INFO",
  };
};

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateTerminationStats = (data: any): Action => ({
  type: "UPDATE_EMPLOYEE_TERMINATION_STATS",
  data,
});

/**
 * @return {*}  {Action}
 */
export const clearTerminationStats = (): Action => ({
  type: "CLEAR_EMPLOYEE_TERMINATION_STATS",
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateEmployeeCards = (data: any): Action => ({
  type: "UPDATE_EMPLOYEE_CARDS",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const triggerEmployeeCardPinVerificationCode = (data: any): Action => ({
  type: "TRIGGER_EMPLOYEE_CARD_PIN_VERIFICATION_CODE",
  data,
});

/**
 * @param {*} data
 * @return {*}  {Action}
 */
export const updateEmployeeCardPin = (data: any): Action => {
  return {
    type: "UPDATE_EMPLOYEE_CARD_PIN",
    data,
  };
};

export const setPinResetCompleted = (data: any): Action => {
  return {
    type: "SET_PIN_RESET_COMPLETED",
    data,
  };
};

export const fetchAccountDeletionInfo = (data: any): Action => {
  return {
    type: "FETCH_ACC_DELETION_INFO",
    data,
  };
};

export const updateAccountDeletionInfo = (data: any): Action => {
  return {
    type: "UPDATE_ACC_DELETION_INFO",
    data,
  };
};

export const triggerAccountDeletion = (data: any): Action => {
  return {
    type: "TRIGGER_ACC_DELETION",
    data,
  };
};

export const triggerAccountDeletionCancellation = (data: any): Action => {
  return {
    type: "TRIGGER_ACC_DELETION_CANCELLATION",
    data,
  };
};

export const triggerAccountDeletionVerificationCode = (data: any): Action => {
  return {
    type: "TRIGGER_ACC_DELETION_VERIFICATIONCODE",
    data,
  };
};

export const clearAccountDeletionInfo = (): Action => {
  return {
    type: "CLEAR_ACC_DELETION_INFO",
  };
};

export const updateFlexAllocation = (data: any): Action => {
  return {
    type: "UPDATE_EMPLOYEE_FLEX_ALLOCATION",
    data,
  };
};

export const setFlexAllocationAPIResponse = (data: any): Action => {
  return {
    type: "SET_EMPLOYEE_FLEX_ALLOCATION_API_RESPONSE",
    data,
  };
};

export const setContributionResponse = (data: any): Action => {
  return {
    type: "SET_EMPLOYEE_CONTRIBUTION_RESPONSE",
    data,
  };
};

export const updateEmployeeContribution = (data: any): Action => {
  return {
    type: "UPDATE_EMPLOYEE_CONTRIBUTION",
    data,
  };
};

export const updateFullEmployeesContribution = (data: any): Action => ({
  type: "UPDATE_FULL_EMPLOYEES_CONTRIBUTION",
  data,
});

export const clearContributionData = (): Action => ({
  type: "CLEAR_CONTRIBUTION_DATA",
});
