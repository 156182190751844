// Types
import { get } from "lodash";
import { Action } from "../actions/types";
import {
  EMPLOYEE_BALANCES,
  EMPLOYEE_PLAN,
  EMPLOYEE_STATS,
} from "./utilities/employee/employee.constant";
import { IEmployeeStats } from "./utilities/employee/employee.interface";
import {
  CraHsaParser,
  storeCRAInfoLocalStorageSuccess,
} from "./utilities/employee/employee.cra.hsa.helper";
import moment from "moment";

type IDeletionInfo = {
  fetchTriggered: boolean;
  cancelDeletionTriggered: boolean;
  hasErrors: boolean;
  isTriggered: unknown;
  initiatedAt: string;
  deletionDate: string;
  deletionGracePeriod: number;
  deletionSuccess: boolean;
  verificationCodeSuccess: boolean;
  cancelVerificationSuccess: boolean;
  cancelVerificationFailed: boolean;
  fetchDeletionInfoFailed: boolean;
};

type IFlexAllocationInfo = {
  apiResponse: any;
};

type IUpdateStatus = {
  success: boolean;
  personGuid: string;
  failed: boolean;
  product: string;
};

type IContributionInfo = {
  apiResponse: any;
  updateStatus: IUpdateStatus;
};

// State types
type State = {
  employees: Array<any>;
  data: any;
  employeesFull: Array<any>;
  planDetails: any;
  planAdminPlanDetails: any;
  hsaWsa: any;
  employeeDetails: any;
  hsaDetails: any;
  wsaDetails: any;
  flexDetails: any;
  employeeStats: IEmployeeStats;
  employeeTerminationStats: Array<any>;
  employeeCards: Array<any>;
  employeeCraHsaInfo: any;
  employeeCraParsedAt: string;
  pinResetCompleted: string;
  accountDeletion: IDeletionInfo;
  fullEmployeeCount: number;
  flexAllocationInfo: IFlexAllocationInfo;
  contributionInfo: IContributionInfo;
};

// Initial state
const INITIAL_STATE: State = {
  employees: [],
  data: {},
  employeesFull: [],
  employeeStats: EMPLOYEE_STATS,
  planDetails: {},
  planAdminPlanDetails: {},
  hsaWsa: {},
  employeeDetails: {},
  hsaDetails: {},
  wsaDetails: {},
  flexDetails: {},
  employeeTerminationStats: [],
  employeeCards: [],
  employeeCraHsaInfo: {},
  employeeCraParsedAt: "",
  pinResetCompleted: "",
  accountDeletion: {
    fetchTriggered: false,
    cancelDeletionTriggered: false,
    hasErrors: false,
    isTriggered: false,
    initiatedAt: "",
    deletionDate: "",
    deletionGracePeriod: 0,
    deletionSuccess: false,
    verificationCodeSuccess: false,
    cancelVerificationSuccess: false,
    cancelVerificationFailed: false,
    fetchDeletionInfoFailed: false,
  },
  fullEmployeeCount: 0,
  flexAllocationInfo: {
    apiResponse: null,
  },
  contributionInfo: {
    apiResponse: null,
    updateStatus: {
      success: false,
      personGuid: "",
      failed: false,
      product: "",
    },
  },
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case "CREATE_EMPLOYEE":
      return { ...state, data: { ...state.data, ...action.data } };
    case "EDIT_EMPLOYEE":
      return { ...state, data: { ...state.data, ...action.data } };
    case "UPDATE_FULL_EMPLOYEES":
      return {
        ...state,
        employeesFull: { ...action.data.employeeList },
        employeeStats: action.data.employeeStats
          ? { ...action.data.employeeStats }
          : state.employeeStats,
        fullEmployeeCount: action.data.fullEmployeeCount ?? 0,
      };
    case "CLEAR_FULL_EMPLOYEES":
      return {
        ...state,
        employeesFull: INITIAL_STATE.employeesFull,
        employeeStats: INITIAL_STATE.employeeStats,
      };
    case "UPDATE_PLAN_DETAILS":
      return { ...state, planDetails: { ...action.data } };
    case "UPDATE_PLANADMIN_PLAN_DETAILS":
      return { ...state, planAdminPlanDetails: { ...action.data } };
    case "UPDATE_PLAN_HSA_WSA":
      let hsaDetails = {
        PlanType: null,
        accountguid: null,
        availablebalance: 0,
        availablebalanceexpirydate: null,
        ayaproductname: null,
        carryoveryear1: 0,
        carryoveryear1expirydate: null,
        carryoveryear2: 0,
        carryoveryear2expirydate: null,
        daysToSubmitClaimPreviousYear: null,
        gracebalance: 0,
        gracebalanceexpirydate: null,
        nextcontributiondate: null,
        openingbalance: 0,
        personguid: null,
        planyearbegin: null,
        planyearend: null,
        prepaid: null,
        totalUsed: 0,
        contributionYearAmount: 0,
      };
      let wsaDetails = {
        PlanType: null,
        accountguid: null,
        availablebalance: 0,
        availablebalanceexpirydate: null,
        ayaproductname: null,
        carryoveryear1: 0,
        carryoveryear1expirydate: null,
        carryoveryear2: 0,
        carryoveryear2expirydate: null,
        daysToSubmitClaimPreviousYear: null,
        gracebalance: 0,
        gracebalanceexpirydate: null,
        nextcontributiondate: null,
        openingbalance: 0,
        personguid: null,
        planyearbegin: null,
        planyearend: null,
        prepaid: null,
        totalUsed: 0,
        contributionYearAmount: 0,
      };
      let flexDetails = {
        PlanType: null,
        accountguid: null,
        availablebalance: 0,
        availablebalanceexpirydate: null,
        ayaproductname: null,
        carryoveryear1: 0,
        carryoveryear1expirydate: null,
        carryoveryear2: 0,
        carryoveryear2expirydate: null,
        daysToSubmitClaimPreviousYear: null,
        gracebalance: 0,
        gracebalanceexpirydate: null,
        nextcontributiondate: null,
        openingbalance: 0,
        personguid: null,
        planyearbegin: null,
        planyearend: null,
        prepaid: null,
        totalUsed: 0,
        contributionYearAmount: 0,
      };
      action.data?.forEach((item) => {
        if (item.ayaproductname.includes("HSA"))
          hsaDetails = {
            ...item,
            prepaid: item.ayaproductname.includes("Prepaid"),
          };
        if (item.ayaproductname.includes("WSA"))
          wsaDetails = {
            ...item,
            prepaid: item.ayaproductname.includes("Prepaid"),
          };
        if (item.ayaproductname.includes("Flex")) flexDetails = item;
      });
      return {
        ...state,
        hsaWsa: {
          ...action.data,
        },
        hsaDetails,
        wsaDetails,
        flexDetails,
      };
    case "UPDATE_EMPLOYEES":
      return { ...state, employees: { ...action.data } };
    case "CLEAR_EMPLOYEE":
      return {
        ...state,
        data: INITIAL_STATE.data,
        planDetails: INITIAL_STATE.planDetails,
        hsaWsa: INITIAL_STATE.hsaWsa,
        employeeDetails: INITIAL_STATE.employeeDetails,
      };
    case "STORE_EMPLOYEE_DETAILS":
      return { ...state, employeeDetails: { ...action.data } };
    case "UPDATE_EMPLOYEE_TERMINATION_STATS":
      return {
        ...state,
        employeeTerminationStats: [...action.data],
      };
    case "CLEAR_EMPLOYEE_TERMINATION_STATS":
      return {
        ...state,
        employeeTerminationStats: INITIAL_STATE.employeeTerminationStats,
      };
    case "UPDATE_EMPLOYEE_CRA_HSA_INFO":
      const parsedJsonValues: Array<any> = CraHsaParser(action.data);
      let parsedAt = "";
      if (parsedJsonValues.length > 0) {
        parsedAt = moment(new Date()).format("LL");
      }
      let craInfoStored = false;
      if (typeof Storage !== "undefined") {
        craInfoStored = storeCRAInfoLocalStorageSuccess(
          parsedJsonValues,
          parsedAt
        );
      }
      if (craInfoStored) {
        return {
          ...state,
          employeeCraHsaInfo: INITIAL_STATE.employeeCraHsaInfo,
        };
      }
      return {
        ...state,
        employeeCraHsaInfo: {
          medicalExpenseList: [...parsedJsonValues],
          employeeCraParsedAt: parsedAt,
        },
      };
    case "CLEAR_EMPLOYEE_CRA_HSA_INFO":
      return {
        ...state,
        employeeCraHsaInfo: INITIAL_STATE.employeeCraHsaInfo,
      };
    case "UPDATE_EMPLOYEE_CARDS":
      return {
        ...state,
        employeeCards: [...action.data],
      };
    case "CLEAR_EMPLOYEE_CARDS":
      return {
        ...state,
        employeeCards: [],
      };
    case "SET_PIN_RESET_COMPLETED":
      return {
        ...state,
        pinResetCompleted: action.data.pinResetCompleted,
      };
    case "UPDATE_ACC_DELETION_INFO":
      return {
        ...state,
        accountDeletion: {
          ...state.accountDeletion,
          ...action.data,
        },
      };
    case "CLEAR_ACC_DELETION_INFO":
      return {
        ...state,
        accountDeletion: INITIAL_STATE.accountDeletion,
      };
    case "SET_EMPLOYEE_FLEX_ALLOCATION_API_RESPONSE":
      return {
        ...state,
        flexAllocationInfo: {
          ...state.flexAllocationInfo,
          apiResponse: action.data,
        },
      };
    case "SET_EMPLOYEE_CONTRIBUTION_RESPONSE":
      return {
        ...state,
        contributionInfo: {
          ...state.contributionInfo,
          apiResponse: action.data.apiResponse,
          updateStatus: { ...action.data.updateStatus },
        },
      };
    case "UPDATE_FULL_EMPLOYEES_CONTRIBUTION":
      if (action?.data?.payload.length === 0) {
        return { ...state };
      }
      const calledBy = action?.data?.calledBy;

      let updatedEmployeeList: any = state.employeesFull;

      action?.data?.payload?.forEach((respData: any) => {
        const product = get(respData, "product", "");
        let propKey = "hsaAmount";
        if (product === "WSA") {
          propKey = "wsaAmount";
        }
        if (product === "Flex") {
          propKey = "flexAmount";
        }

        if (calledBy === "PA") {
          Object.keys(updatedEmployeeList).forEach((key: any) => {
            let employee = state.employeesFull[key];
            if (
              employee?.personGuid &&
              employee?.personGuid === respData.personGuid
            ) {
              employee[propKey] = respData.contribution;
            }
          });
        } else {
          updatedEmployeeList.listData?.forEach((employee: any) => {
            if (
              employee?.employeeGuid &&
              employee?.employeeGuid === respData.personGuid
            ) {
              employee[propKey] = respData.contribution;
            }
          });
        }
      });

      return {
        ...state,
        employeesFull: { ...updatedEmployeeList },
      };
    case "CLEAR_CONTRIBUTION_DATA":
      return {
        ...state,
        contributionInfo: INITIAL_STATE.contributionInfo,
      };
    default:
      return state;
  }
};
